import dayjs from "dayjs";
import {
    LOCAL_STORAGE_CACHE_MEASURE,
    LOCAL_STORAGE_CACHE_VALUE
} from "./const";

export const isDemoMode = () => typeof tlPriceLoadMode !== 'undefined' && tlPriceLoadMode === 'demo';

export const reverseString = (text) => text.split("").reverse().join("");

export const getNowTimeStamp = () => dayjs().unix();

export const getExpireTime = () => dayjs().add(LOCAL_STORAGE_CACHE_VALUE, LOCAL_STORAGE_CACHE_MEASURE).unix();