import {LANG_SETTING} from "../settings";
import {DEFAULT_LANG, LANGUAGE_CODE_STANDARD_LENGTH, LANGUAGE_CONSTANT} from "../const";

export const getSiteLanguage = () => {
    const pageLang = document.documentElement.lang;
    const settingLanguages = Object.keys(LANG_SETTING);
    const constLanguages = Object.keys(LANGUAGE_CONSTANT);

    const siteLang = pageLang.length === LANGUAGE_CODE_STANDARD_LENGTH
        ? pageLang.toLowerCase()
        : pageLang.substring(0, LANGUAGE_CODE_STANDARD_LENGTH);

    return (!settingLanguages.includes(siteLang) || !constLanguages.includes(siteLang))
        ? DEFAULT_LANG
        : siteLang;
};
